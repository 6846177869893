<template>
	<div id="search-result">
		<div class="search_result_row_1">
			<a class="search_result_image" v-bind:style="{ backgroundImage: 'url(' + image1 + ')' }" :href="image1" :data-lightbox="'lightbox_'+this.result_data.ID">
				<span>{{ title }}</span>	
			</a>
			<a class="image_gallery" v-if="image2" :href="image2" :data-lightbox="'lightbox_'+this.result_data.ID">#</a>
			<a class="image_gallery" v-if="image3" :href="image3" :data-lightbox="'lightbox_'+this.result_data.ID">#</a>
			<div style="padding: 20px; display: flex; flex-direction: column; width: 100%;">
				<div class="search_result_description">
					<h3 v-html="title"></h3>
					<span v-html="description"></span>
				</div>
				<br>
				<div class="general_price">
					<div class="search_result_policy_price_tip">{{ getTranslation('STARTING_WITH') }}</div>
					<div class="search_result_policy_price">{{ minPrice() }} €</div>
					<div class="search_result_button" @click="mostraRow2()" style="width: 40%; text-align: center;">{{ getTranslation('SHOW_PROPOSALS') }} <i v-if="!isVisible" class="fas fa-chevron-down"></i> <i v-if="isVisible" class="fas fa-chevron-up"></i></div>
				</div>
			</div>
		</div>
		<Transition>
			<div v-show="isVisible" class="search_result_row_2">
				<div class="search_result_policy" v-for="(policy, key) in policies" v-bind:key="key">
					<div class="search_result_policy_row">
						<div class="search_result_policy_left">
							<div class="search_result_policy_arrangement noselect"><span v-html="getArrangementName(policy)"></span>&nbsp;<i class="fas fa-info-circle" v-if="checkShowableArrangementDescription(policy)" @click="toggleShowArrangementDescription(policy)"></i></div>
							<div class="search_result_policy_arrangement_description noselect" v-html="getArrangementDescription(policy)" v-if="checkShowArrangementDescription(policy)"></div>
							<div>
								<div class="search_result_policy_policy">{{ getPolicyName(policy) }}</div>
								<a class="secondario" style="cursor: pointer;" @click.prevent="termsPopup(getPolicyDescription(policy))" href="">
									{{ getTranslation('QUOTA_POLICY') }}
								</a>
							</div>
						</div>
						<div class="search_result_policy_right">
							<div class="search_result_policy_price">{{ policy.TOTAL_PRICE_FORMATTED }} €</div>
							<div class="search_result_policy_price_tip">{{ getTranslation('PRICEFOR') }} {{ nights }} {{ nights_text }}</div>
							<div class="search_result_button" @click="selectPolicy(policy)">{{ getTranslation('PROCEED') }}</div>
						</div>
					</div>
				</div>
			</div>
		</Transition>
	</div>
</template>

<script>
import lightbox from 'lightbox2'

export default {
	name: 'SearchResult',
	props: ['result_data'],
	data() {
		return{
			isVisible: false
		}
	},
	computed: {
		booking_type: function() {
			return this.$store.state.booking_type;
		},
		image1: function() {
			var image = encodeURI(this.$baseURL + '/structures/'+this.$company+'/'+this.$store.state.company_id+'/rooms/' + this.result_data.ID + '/' + this.result_data.IMAGE1);
			image = image.replace("(", "%28");
			image = image.replace(")", "%29");
			return image;
		},
		image2: function() {
			if(this.result_data.IMAGE2 != '') {
				var image = encodeURI(this.$baseURL + '/structures/'+this.$company+'/'+this.$store.state.company_id+'/rooms/' + this.result_data.ID + '/' + this.result_data.IMAGE2);
				image = image.replace("(", "%28");
				image = image.replace(")", "%29");
				return image;
			} else {
				return false;
			}
		},
		image3: function() {
			if(this.result_data.IMAGE3 != '') {
				var image = encodeURI(this.$baseURL + '/structures/'+this.$company+'/'+this.$store.state.company_id+'/rooms/' + this.result_data.ID + '/' + this.result_data.IMAGE3);
				image = image.replace("(", "%28");
				image = image.replace(")", "%29");
				return image;
			} else {
				return false;
			}
		},
		title: function() {
			return this.result_data['NAME_'+this.$store.getters.get_lang_code];
		},
		description: function() {
			return this.result_data['DESCRIPTION_'+this.$store.getters.get_lang_code];
		},
		policies: function() {
			return this.result_data.POLICIES;
		},
		nights: function() {
			return this.$calculateNights(new Date(this.$store.state.last_search.check_in), new Date(this.$store.state.last_search.check_out));
		},
		nights_text: function() {
			if(this.nights == 1) {
				return this.getTranslation('NIGHT');
			} else {
				return this.getTranslation('NIGHTS');
			}
		},
		rooms: function() {
			return this.$store.state.rooms;
		},
	},
	methods: {
		mostraRow2: function() {
			this.isVisible = !this.isVisible
		},
		minPrice: function() {
			let min = 0
			this.policies.forEach(policy => {
				if(min == 0 || policy.TOTAL_PRICE < min){
					min = policy.TOTAL_PRICE
				}
			});
			let formatMin = parseFloat(min);
			return formatMin.toFixed(2).replace('.', ',');
		},
		termsPopup: function(policy) {
			window.$(`
				<div class="privacy_popup" id="policy_popup">
					`+policy+`
				</div>
			`).appendTo('body').modal();
		},
		getTranslation: function(key) {
			return this.$store.getters.get_translation(key);
		},
		getArrangementName: function(policy) {
			return policy['ARRANGEMENT_NAME_'+this.$store.getters.get_lang_code];
		},
		checkShowableArrangementDescription: function(policy) {
			if(policy['ARRANGEMENT_DESCRIPTION_'+this.$store.getters.get_lang_code] != '' && policy['ARRANGEMENT_NAME_'+this.$store.getters.get_lang_code] != policy['ARRANGEMENT_DESCRIPTION_'+this.$store.getters.get_lang_code]) {
				return true;
			}
			return false;
		},
		getArrangementDescription: function(policy) {
			return policy['ARRANGEMENT_DESCRIPTION_'+this.$store.getters.get_lang_code];
		},
		checkShowArrangementDescription: function(policy) {
			if(policy['ARRANGEMENT_DESCRIPTION_SHOW'] == 1) {
				return true;
			} else {
				return false;
			}
		},
		toggleShowArrangementDescription: function(policy) {
			if(policy['ARRANGEMENT_DESCRIPTION_SHOW'] == 0) {
				policy['ARRANGEMENT_DESCRIPTION_SHOW'] = 1;
			} else {
				policy['ARRANGEMENT_DESCRIPTION_SHOW'] = 0;
			}
		},
		getPolicyName: function(policy) {
			return policy['POLICY_NAME_'+this.$store.getters.get_lang_code];
		},
		getPolicyDescription: function(policy) {
			if(policy['POLICY_SERVICES_'+this.$store.getters.get_lang_code] == '') {
				return policy['POLICY_DESCRIPTION_'+this.$store.getters.get_lang_code];
			} else if(policy['POLICY_DESCRIPTION_'+this.$store.getters.get_lang_code] == '') {
				return policy['POLICY_SERVICES_'+this.$store.getters.get_lang_code];
			} else {
				return policy['POLICY_SERVICES_'+this.$store.getters.get_lang_code] + '<br/>' + policy['POLICY_DESCRIPTION_'+this.$store.getters.get_lang_code];
			}
		},
		selectPolicy: function(policy) {
			this.$store.commit('setRoomSelected', this.result_data);
			this.$store.commit('setPolicySelected', policy);

			if(this.$store.state.tracking_codes['TRACKING_SCRIPT_ROOM_SELECTED'] != undefined) {
				let tracking_code = this.$store.state.tracking_codes['TRACKING_SCRIPT_ROOM_SELECTED'];

				let price = 0;
				for(let index = 0; index < this.rooms.length; index++) {
					if(this.rooms[index].policy_selected.TOTAL_PRICE != undefined) {
						price += this.rooms[index].policy_selected.TOTAL_PRICE;
					}
				}

				let piwik_items = '';
				for(let index = 0; index < this.rooms.length; index++) {
					if(this.room_ID(index) != undefined) {
						piwik_items += `
							{
								sku: '`+this.room_ID(index)+`',
								name: '`+this.room_name(index)+`',
								price: `+this.room_price_not_formatted(index).toFixed(2)+`,
								quantity: 1,
							},
						`;
					}
				}
				tracking_code = tracking_code.replace(/%piwikitems%/g, piwik_items);

				tracking_code = tracking_code.replace(/%total%/g, price.toFixed(2));

				tracking_code = tracking_code.replace(/%rid/g, this.result_data['ID']);
				tracking_code = tracking_code.replace(/%rn/g, this.result_data['NAME_IT']);
				tracking_code = tracking_code.replace(/%p/g, policy['TOTAL_PRICE'].toFixed(2));

				eval(tracking_code);
			}

			this.$store.dispatch('getExtraCharges');
		},
		room_ID: function(index) {
			if(this.booking_type == 1) {
				return this.$store.state.rooms[index].room_selected['ID'];
			} else if(this.booking_type == 2) {
				return this.$store.state.package.package_room_selected['ID'];
			}
			return 0;
		},
		room_name: function(index) {
			if(this.booking_type == 1) {
				return this.$store.state.rooms[index].room_selected['NAME_'+this.$store.getters.get_lang_code];
			} else if(this.booking_type == 2) {
				return this.$store.state.package.package_room_selected['NAME_'+this.$store.getters.get_lang_code];
			}
			return '';
		},
		room_price_not_formatted: function(index) {
			if(this.booking_type == 1) {
				return this.$store.state.rooms[index].policy_selected.TOTAL_PRICE;
			} else if(this.booking_type == 2) {
				return this.$store.state.package.package_room_selected.TOTAL_PRICE;
			}
			return '';
		},
	},
	mounted: function() {
		lightbox.option({
			albumLabel: '',
			wrapAround: true,
			fadeDuration: 300
		})
	}
}
</script>
<style>
	.general_price{
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		align-content: flex-end;
		align-items: center;
	}
</style>