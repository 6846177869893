<template>
    <div class="quota_load">
        <div class="quota_load_logo"><a :href="website" target="_blank"><img :src="logo" /></a></div>

        <div class="container">
            <h1>{{ getTranslation('REQUEST_ASK') }}</h1>
			<div class="request_language_selector">
				<img v-if="this.langs.includes('IT')" src="@/assets/images/flags/it.png" v-bind:class="{selected: lang == 'IT'}" @click="setLang(0)" />
				<img v-if="this.langs.includes('EN')" src="@/assets/images/flags/en.png" v-bind:class="{selected: lang == 'EN'}" @click="setLang(1)" />
				<img v-if="this.langs.includes('DE')" src="@/assets/images/flags/de.png" v-bind:class="{selected: lang == 'DE'}" @click="setLang(2)" />
				<img v-if="this.langs.includes('FR')" src="@/assets/images/flags/fr.png" v-bind:class="{selected: lang == 'FR'}" @click="setLang(3)" />
				<img v-if="this.langs.includes('PT')" src="@/assets/images/flags/pt.png" v-bind:class="{selected: lang == 'PT'}" @click="setLang(4)" />
				<img v-if="this.langs.includes('ES')" src="@/assets/images/flags/es.png" v-bind:class="{selected: lang == 'ES'}" @click="setLang(5)" />
				<img v-if="this.langs.includes('RU')" src="@/assets/images/flags/ru.png" v-bind:class="{selected: lang == 'RU'}" @click="setLang(6)" />
				<img v-if="this.langs.includes('CH')" src="@/assets/images/flags/ch.png" v-bind:class="{selected: lang == 'CH'}" @click="setLang(7)" />
			</div>
        </div>
		<br>
		<div class="checkout_section_row">
			<div class="checkout_section_col">
				<label for="first_name">{{ getTranslation('NAME') }} <span class="error"></span></label>
				<input type="text" id="first_name" name="first_name" v-model="first_name" />
			</div>
			<div class="checkout_section_col">
				<label for="last_name">{{ getTranslation('SURNAME') }} <span class="error"></span></label>
				<input type="text" id="last_name" name="last_name" v-model="last_name" />
			</div>
		</div>
		<div class="checkout_section_row">
			<div class="checkout_section_col">
				<label for="email">{{ getTranslation('EMAIL') }} <span class="error"></span></label>
				<input type="email" id="email" name="email" v-model="email" />
			</div>
			<div class="checkout_section_col">
				<label for="tel">{{ getTranslation('PHONE') }} <span class="error"></span></label>
				<input type="tel" id="tel" name="tel" v-model="tel" />
			</div>    
		</div>
		<div class="checkout_section_row" style="align-items: flex-end;">
			<div class="checkout_section_col">
				<label for="data-picker">{{ getTranslation('REQUEST_DATES') }}</label>
				<input id="date-picker" type="text" readonly placeholder="Seleziona date" />
			</div>
			<div class="checkout_section_col checkboxes">
				<input id="flexibleDates" type="checkbox" value="1" v-model="flexibleDates">
				<label for="flexibleDates">{{ getTranslation('REQUEST_FLEXIBLE') }}</label>
			</div>
		</div>
		<div class="checkout_section_row">
			<div class="checkout_section_col">
				<label for="nradult">{{ getTranslation('ADULT') }}</label>
				<select name="nradult" id="nradult" v-model="nradult">
					<option v-for="num in 100" :key="num" :value="num">{{ num }}</option>
				</select>
			</div>
			<div class="checkout_section_col">
				<label for="nrchild1">{{ getTranslation('CHILD') }} <span>({{ ages.child1_age }})</span></label>
				<select name="nrchild1" id="nrchild1" v-model="nrchild1">
					<option> 0 </option>
					<option v-for="num in 10" :key="num" :value="num">{{ num }}</option>
				</select>
			</div>
			<div class="checkout_section_col" v-if="ages.child2_age != null">
				<label for="nrchild2">{{ getTranslation('CHILD') }} <span>({{ ages.child2_age }})</span></label>
				<select name="nrchild2" id="nrchild2" v-model="nrchild2">
					<option> 0 </option>
					<option v-for="num in 10" :key="num" :value="num">{{ num }}</option>
				</select>
			</div>
			<div class="checkout_section_col">
				<label for="nrinfant">{{ getTranslation('INFANT') }} <span>({{ ages.infant_age }})</span></label>
				<select name="nrinfant" id="nrinfant" v-model="nrinfant">
					<option> 0 </option>
					<option v-for="num in 10" :key="num" :value="num">{{ num }}</option>
				</select>
			</div>
		</div>
		<div class="checkout_section_row">
			<div class="checkout_section_col">
				<label for="nrlodge">{{ getTranslation('LODGE') }}</label>
				<select name="nrlodge" id="nrlodge" v-model="nrlodge">
					<option v-for="num in 10" :key="num" :value="num">{{ num }}</option>
				</select>
			</div>
			<div class="checkout_section_col">
				<label for="arrangement">{{ getTranslation('QUOTA_ARRANGEMENT') }} <span class="error"></span></label>
				<select name="arrangement" id="arrangement" v-model="arrangement">
					<option v-for="arrangement in arrangements" v-bind:key="arrangement.ID" v-bind:value="arrangement.ID">{{ arrangement['NAME_'+ setCodeLang()] }}</option>
				</select>
			</div>
		</div>
		<div class="checkout_section_row">
			<div class="checkout_section_col">
				<label for="notes">{{ getTranslation('REQUESTS') }}</label>
				<textarea id="notes" name="notes" rows="4" v-model="notes"></textarea>
			</div>
		</div>
		<div class="checkout_section_row">
			<div class="checkout_section_col checkboxes">
				<input id="privacy_policy" type="checkbox" value="1" v-model="privacy_policy">
				<label for="privacy_policy"><a href="#" @click.prevent="privacyPopup()">{{ getTranslation('PRIVACY') }}</a> &nbsp; <span class="error"></span></label>
			</div>
		</div>
			
		<div class="checkout_button_container">
			<div class="checkout_button" @click="sendRequest()">{{ getTranslation('REQUEST_SEND') }}</div>
		</div>
		<!-- POP-UP -->
        <RequestQuotaModal :isVisible="show_modal">
        </RequestQuotaModal>
    </div>
</template>

<script>
import Litepicker from 'litepicker';
import jQuery from 'jquery'
import RequestQuotaModal from './RequestQuotaModal.vue';
let $ = jQuery


    export default{
        name: 'Quota',

		components: {
            RequestQuotaModal
        },

		data() {
			return {
				isRecaptchaLoaded: false,
				langs: [],
				calendar_lang: 'it-IT',
				first_name: '',
				last_name: '',
				email: '',
				tel: '',
				check_in: '',
				check_out: '',
				flexibleDates: false,
				nradult: 2,
				nrchild1: 0,
				nrchild2: 0,
				nrinfant: 0,
				nrlodge: 1,
				arrangement: '',
				arrangements: '',
				notes: '',
				privacy_policy: false,
				show_modal: false,
				modal_message: '',
			}
		},

        computed: {
            logo: function() {
                return encodeURI(this.$baseURL + '/structures/'+this.$company+'/'+this.$store.state.company_id+'/company/'+ this.$store.state.company_info.logo);
            },
            website: function() {
                return 'https://' + this.$store.state.company_info.website;
            },
			ages: function() {
                return this.$store.state.ages;
            },
			lang: function() {
				return this.$store.getters.get_lang_code;
			}
        },

		methods: {  
			// funzione per caricare lo script di google recaptcha
			loadRecaptcha() {
				if (document.getElementById('recaptcha-script')) {
					this.isRecaptchaLoaded = true;
					return Promise.resolve();
				}
				return new Promise((resolve, reject) => {
					const script = document.createElement('script');
					script.id = 'recaptcha-script';
					script.src = 'https://www.google.com/recaptcha/api.js?render=6LfahpoqAAAAACs0FmFp1IHxdHlsuvdLBzWH_UbZ';
					script.async = true;
					script.defer = true;

					script.onload = () => {
						this.isRecaptchaLoaded = true;
						resolve();
					};
					script.onerror = () => {
						console.error('Errore durante il caricamento di reCAPTCHA.');
						reject();
					};

					document.head.appendChild(script);
				});
			},
			setLang: function(lang) {
				this.$store.commit('setLang', lang);
			},
			getTranslation: function(key) {
				return this.$store.getters.get_translation(key);
			},
			setCodeLang: function() {
				return String(this.$store.getters.get_lang_code);
			},
			getPrivacyText: function() {
				let privacy_text = this.getTranslation('PRIVACYLAW');
				privacy_text = privacy_text.replace(/#COMPANY#/g, this.$store.state.company_info.name + ' - Partita IVA: ' + this.$store.state.company_info.vat);
				privacy_text = privacy_text.replace(/#EMAIL#/g, this.$store.state.company_info.email);
				return privacy_text;
			},
			privacyPopup: function() {
                window.$(`
                    <div class="privacy_popup" id="privacy_popup">
                        `+this.getPrivacyText()+`
                    </div>
                `).appendTo('body').modal();
            },
			openModal() {
                this.show_modal = true; 
            },
			sendRequest: function() {

				let all_ok = true

				if(this.first_name == '') {
					$('#first_name').addClass('invalid_input');
					$('#first_name').siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i> ' + this.getTranslation('REQUIRED_FIELD'));
					all_ok = false;
				}
				if(this.last_name == '') {
					$('#last_name').addClass('invalid_input');
					$('#last_name').siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i> ' + this.getTranslation('REQUIRED_FIELD'));
					all_ok = false;
				}

				if(this.email == '') {
					$('#email').addClass('invalid_input');
					$('#email').siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i> ' + this.getTranslation('REQUIRED_FIELD'));
					all_ok = false;
				} else {
					const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
					if (!emailPattern.test(this.email)) {
						$('#email').addClass('invalid_input');
						$('#email').siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i> ' + this.getTranslation('INVALID_EMAIL'));
						all_ok = false;
					}
				}
				
				if(this.arrangement == '') {
					$('#arrangement').addClass('invalid_input');
					$('#arrangement').siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i> ' + this.getTranslation('REQUIRED_FIELD'));
					all_ok = false;
				}

				if(this.privacy_policy == false) {
					$('#privacy_policy').addClass('invalid_input');
					$('#privacy_policy').siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i> ' + this.getTranslation('REQUIRED_FIELD'));
					all_ok = false;
				}

				if(all_ok){
					const request = {
						lang: this.$store.state.lang,
						name: this.first_name,
						surname: this.last_name,
						email: this.email,
						phone: this.tel,
						checkIn: this.$formatDateIt(this.check_in),
						checkOut: this.$formatDateIt(this.check_out),
						flexibleDates: this.flexibleDates, 
						adult: this.nradult,
						child1: this.nrchild1,
						child2: this.nrchild2,
						infant: this.nrinfant,
						lodge: this.nrlodge,
						arrangement: this.arrangement,
						notes: this.notes,
						policy: this.privacy_policy
					}

					window.grecaptcha.execute('6LfahpoqAAAAACs0FmFp1IHxdHlsuvdLBzWH_UbZ', { action: 'submit_form' })
					.then((token) => {
					
						var bodyFormData = new FormData();
						bodyFormData.append('request', JSON.stringify(request));
						bodyFormData.append('token', token);
						this.$axios({
							method: 'post',
							url: '/booking/'+this.$company+'/sendRequestQuota',
							data: bodyFormData
						}).then((response) => {
							if(response.data == 0 || response.data == 4){
								console.log('error!')
							}else{
								this.sendConfirmMail()
								this.show_modal = true
							}
						});
					})
				}
			},
			sendConfirmMail() {
				let arrangementDescription
				this.arrangements.forEach(arrang => {
					if (arrang.ID == this.arrangement){
						arrangementDescription = arrang['NAME_'+this.setCodeLang()]
					}
				})

				var bodyFormData = new FormData;

				bodyFormData.append('type', 2);
				bodyFormData.append('lang', this.$store.getters.get_lang_code);
				bodyFormData.append('recipient', this.email);
	
				let summary = ``;

				summary += `<tr><td colspan="2" style="border: 1px solid #AAAAAA; border-radius: 3px; padding: 10px 14px;"><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tbody><tr><td style="vertical-align: middle">` + this.getTranslation('GUEST') + `</td><td><b>` +this.first_name + ` ` + this.last_name + `</b></td></tr>`;
				summary += `<tr><td>` + this.getTranslation('EMAIL') + `</td><td><b>` + this.email + `</b></td></tr>`;
				summary += `<tr><td>` + this.getTranslation('PHONE') + `</td><td><b>` + this.tel + `</b></td></tr>`;
				summary += `<tr><td>Date</td><td><b>` + this.$formatDateIt(this.check_in) + ` - ` + this.$formatDateIt(this.check_out) + `</b></td></tr>`;
				summary += `<tr><td>` + this.getTranslation('LODGE') + `</td><td><b>` + this.nrlodge + `</b></td></tr>`;
				summary += `<tr><td>` + this.getTranslation('ADULT') + `</td><td><b>` + this.nradult + `</b></td></tr>`;
				if(this.nrchild1 > 0){
					summary += `<tr><td>` + this.getTranslation('CHILD') +` (`+ this.ages.child1_age + `)</td><td><b>` + this.nrchild1 + `</b></td></tr>`;
				}
				if(this.nrchild2 > 0){
					summary += `<tr><td>` + this.getTranslation('CHILD') +` (`+ this.ages.child2_age + `)</td><td><b>` + this.nrchild2 + `</b></td></tr>`;
				}
				if(this.nrinfant > 0){
					summary += `<tr><td>` + this.getTranslation('INFANT') +` (`+ this.ages.infant_age + `)</td><td><b>` + this.nrinfant + `</b></td></tr>`;
				}				
				summary += `<tr><td>` + this.getTranslation('QUOTA_ARRANGEMENT') + `</td><td><b>` + arrangementDescription + `</b></td></tr>`;
				summary += `<tr><td>` + this.getTranslation('REQUESTS') + `</td><td>` + this.notes + `</td></tr>`;

				summary += `</tbody></table></td></tr>`;

				bodyFormData.append('summary', summary);

				this.$axios({
					method: 'post',
					url: '/booking/'+this.$company+'/requestQuotaSendConfirmationEmail/'+this.$store.state.company_id,
					data: bodyFormData
				});
			}
		},

		beforeCreate: function () {
			var self = this;

			this.$axios({
				method: 'get',
				url: '/booking/'+this.$company+'/getLangs'
			}).then(function (response) {
				self.langs = response.data;
			});
		},

		created() {
			var self = this

			this.$axios({
				method: 'get',
				url: '/booking/'+this.$company+'/getArrangements'
			}).then(function (response) {
				self.arrangements = response.data
				self.arrangement = response.data[0].ID
			});

		},

		mounted() {
			
			this.loadRecaptcha();

			jQuery('input').focus(function() {
				$(this).removeClass('invalid_input');
				$(this).siblings('label').find('.error').html('');
			});

			const today = new Date();
			const tomorrow = new Date();
			tomorrow.setDate(today.getDate() + 1);

			this.check_in = today
			this.check_out = tomorrow

			// Inizializza Litepicker
			this.picker = new Litepicker({
				element: document.getElementById('date-picker'),
				lang: this.calendar_lang, // Imposta la lingua
				singleMode: false, // Modalità intervallo di date
				startDate: today, // Data di inizio predefinita
				endDate: tomorrow, // Data di fine predefinita
				minDate: today, // Non selezionabile prima di oggi
				format: 'DD/MM/YYYY', // Formato delle date visualizzate
				tooltipText: {
					one: 'notte',
					other: 'notti',
				},
				tooltipNumber: (totalDays) => totalDays - 1, // Mostra "notti" nel tooltip
				autoApply: true, // Applica automaticamente le date selezionate
				setup: (picker) => {
					// Aggiungi un listener per l'evento "selected"
					picker.on('selected', (start, end) => {
						this.check_in = start.dateInstance
						this.check_out = end.dateInstance
					});
				},
			});

			// Aggiorna il placeholder con le date predefinite
			document.getElementById('date-picker').value = `${this.$formatDateIt(today)} - ${this.$formatDateIt(tomorrow)}`;
		},
    }
</script>