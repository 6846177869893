<template>
    <div v-if="isVisible" class="modal-overlay">
      <div class="modal-content">
        <h2 style="margin-bottom: 20px;">{{ getTranslation('REQUEST_SUCCESS') }}</h2>
        <p style="margin-bottom: 15px;" v-html="getTranslation('REQUEST_SUMMARY')"></p>
        <button class="quota_load_button" @click="returnHome()">{{ getTranslation('REQUEST_BACKHOME') }}</button>
        <slot></slot>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      isVisible: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
    //   closeModal() {
    //     this.$emit('close');
    //     window.location.reload();
    //   },
        returnHome(){
            window.location = 'https://' + this.$store.state.company_info.website;
        },
        getTranslation: function(key) {
          return this.$store.getters.get_translation(key);
        },
    },
  };
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
    width: 100%;
    text-align: center;
  }
  </style>