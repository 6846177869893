<template>
    <div class="main" id="main">
        <BookingMain v-if="type == 'booking' || type == 'checkout' || type == 'fail' || type == 'paid'"></BookingMain>
        <DeleteReservation v-if="type == 'delete'"></DeleteReservation>
        <QuotaLoad v-if="type == 'quotas'"></QuotaLoad>
        <RequestQuota v-if="type == 'requestQuota'"></RequestQuota>
    </div>
</template>
  
<script>
import BookingMain from './components/BookingMain.vue'
import DeleteReservation from './components/DeleteReservation.vue'
import QuotaLoad from './components/Quota/QuotaLoad.vue'
import RequestQuota from './components/Quota/RequestQuota.vue';

export default {
    name: 'App',
    components: {
        BookingMain,
        DeleteReservation,
        QuotaLoad,
        RequestQuota
    },
    computed: {
        type: function() {
            return this.$type;
        },
        company_name: function() {
            return this.$store.state.company_info.name;
        }
    },
    metaInfo() {
        return {
            title: this.company_name,
            titleTemplate: '%s - IsidoroBooking'
        }
    }
}
</script>
  
<style>
    @import './assets/css/style.css';
    @import './assets/css/all.min.css';
    @import '~lightbox2/dist/css/lightbox.min.css';
</style>