<template>
    <div class="quota_load">
        <div v-if="hasrows"> <!-- verificare se preventivo esiste -->
            <div class="quota_load_logo"><a :href="website" target="_blank"><img :src="logo" /></a></div>

            <!-- DESCRIZIONE GENERALE -->
            <div class="container" v-if="!status_check">
                <h1>{{ getTranslation('QUOTA_OFFER') }}</h1>
                <p>{{ getTranslation('QUOTA_VALIDITY') }} <b>{{ expiration }}</b><br>
                    {{ getTranslation('QUOTA_GENTLE') }} <b>{{ quotas[1][0].REFERENCE }}</b>
                </p>
                <p v-html="transformString(getTranslation('QUOTA_DES'))"></p>
                
            </div>
            <div class="container" v-else>
                <h1>{{ getTranslation('QUOTA_CONFIRMED') }}</h1>
                <p>{{ getTranslation('QUOTA_GENTLE') }} <b>{{ quotas[1][0].REFERENCE }}</b><br>
                </p>
                <p v-html="getTranslation('QUOTA_CONFIRM_DES')"></p>
            </div>
        
            <div class="quota_load_navtab" v-if="Object.values(quotas).length > 1">
                <button 
                    class="quota_load_button_nav" 
                    v-for="(quotas, key) in quotas" 
                    :key="key"  
                    @click="selectNrchoice(key)"
                    :class="{active: selectedQuota == key}"
                    :disabled="allowedKey !== null && key != allowedKey"
                >
                    {{ getTranslation('QUOTA_PROPOSAL') }}{{ key }}
                </button>
            </div>
            
            <Quotas
                :isPastDate = isPastDate(expiration)
                :validation_token = validation_token
                :status_check = status_check
            ></Quotas>
        </div>
        <div v-if="!hasrows">
            <div class="logo"><a>{{ getTranslation('QUOTA_NOT_FOUND') }}</a></div>
        </div>
    </div>
</template>

<script>
import Quotas from './Quotas.vue';

export default {
    name: 'QuotaLoad',

    components: {
        Quotas
    },

    data: function () {

        return {
            hasrows: '',
            expiration: '',
            selectedQuota: 1,
            check_status: '',
            allowedKey: null
        }
    },

    computed: {
        logo: function() {
			return encodeURI(this.$baseURL + '/structures/'+this.$company+'/'+this.$store.state.company_id+'/company/' + this.$store.state.company_info.logo);
		},
        website: function() {
			return 'http://' + this.$store.state.company_info.website;
		},
        quotas: function() {
            return this.$store.state.quotas;
        },
        quota: function() {
            return this.$store.state.quotas[1];
        },
        nrchoice: function() {
            return this.$store.state.quota.nrchoice;
        },
        total_lodge: function() {
            var nrchoice = this.nrchoice;
            return this.$store.state.quotas_total_lodge[nrchoice];
        },
        total_guest: function() {
            var nrchoice = this.nrchoice;
            return this.$store.state.quotas_total_guest[nrchoice];
        },
        checkin: function() {
            var nrchoice = this.nrchoice;
            return this.$store.state.quotas_checkin[nrchoice];
        },
        checkout: function() {
            var nrchoice = this.nrchoice;
            return this.$store.state.quotas_checkout[nrchoice];
        },
        status_check: function() {
            if (this.check_status == null){
                return false;
            }else{
                if(this.check_status.STATUS == 2 || this.check_status.STATUS == 51){
                    var self = this;
                    self.selectedQuota = this.check_status.NRCHOICE;
                    self.selectNrchoice(this.check_status.NRCHOICE);
                    self.allowedKey = this.check_status.NRCHOICE;
                    return true;
                }else{
                    return false;
                }
            }
        }
	},

    methods: {  
        getTranslation: function(key) {
            return this.$store.getters.get_translation(key);
        },
        selectNrchoice: function(key) {
            this.$store.commit('setQuotaNrchoice', key);
            this.selectedQuota = key;
        },
        setCodeLang: function() {
            return String(this.$store.getters.get_lang_code);
        },
        // Funzione per convertire 'dd/mm/yyyy' in un oggetto Date
        parseDate(dateStr) {
            const [day, month, year] = dateStr.split('-');
            return new Date(`${year}-${month}-${day}`);
        },
        // Funzione per verificare se la data è passata
        isPastDate(dateStr) {
            const date = this.parseDate(dateStr);
            const today = new Date();
            // Ignoriamo l'ora confrontando solo la parte della data
            return date < today.setHours(0, 0, 0, 0);
        },
        transformString(stringa) {
            return stringa.replace('#', this.getTranslation('QUOTA_ACCEPT'));
        },
    },

    created: function () {

        let params = window.location.pathname.split('/').slice(1)
        this.quota_id = params[2]
        this.validation_token = params[3]
        this.preview = params[4]

        this.$store.commit('setQuotaId', this.quota_id);
        this.$axios({
            method: 'get',
            url: '/booking/'+this.$company+'/loadQuota/'+this.quota_id+'/'+this.validation_token
        }).then(response =>{
            if(response.data){
                this.hasrows = response.data.hasrows;
                this.expiration = response.data.expiration;
                this.check_status = response.data.check_status;
                this.$store.commit('setQuotas', response.data.quotas);
                this.$store.commit('setQuotasNewQty', response.data.newQty);
                this.$store.commit('setQuotasCheckin', response.data.checkin);
                this.$store.commit('setQuotasCheckout', response.data.checkout);
                this.$store.commit('setQuotasTotalguest', response.data.total_guest);
                this.$store.commit('setQuotasTotallodge', response.data.total_lodge);
                this.$store.commit('setQuotasTotalnights', response.data.total_nights);
                this.$store.commit('setQuotasTotal', response.data.total);
                this.$store.commit('setQuotasTotalprice', response.data.total_price);
                this.$store.commit('setQuotasAmount', response.data.amount);
                this.$store.commit('setQuotasServices', response.data.quotas_services);
                this.$store.commit('setQuotasFirstdayprice', response.data.firstdayprice);
                this.$store.commit('setLang', response.data.lang);   

                if(response.data.hasrows && this.preview !== 'preview'){
                    var self = this
                    var bodyFormData = new FormData();
                    bodyFormData.append('quota_id', self.quota_id);
                    bodyFormData.append('validation_token', self.validation_token);
                    this.$axios({
                        method: 'post',
                        url: '/booking/'+self.$company+'/onPageOpen',
                        data: bodyFormData
                    })
                }
            }
        });

        
    }, 
}
</script>