<template>
    <div class="quota_load_section">
        <div class="checkout_section_row">
            <div class="checkout_section_col">
                <label for="cc_type">{{ getTranslation('CRCTYPE') }} <span class="error"></span></label>
                <select id="cc_type" name="cc_type" v-model="cc_type">
                    <option></option>
                    <option v-for="payment_method in payment_methods" v-bind:key="payment_method.ID" v-bind:value="payment_method.ID">{{ payment_method.DESCRIPTION }}</option>
                </select>
            </div>
            <div class="checkout_section_col" v-if="cc_type_nickname != ''">
                <label for="cc_number">{{ getTranslation('CRCNUM') }} <span class="error"></span></label>
                <input type="text" id="cc_number" name="cc_number" v-model="cc_number" />
            </div>
            <div class="checkout_section_col" v-else></div>
        </div>
        <div class="checkout_section_row" v-if="cc_type_nickname != ''">
            <div class="checkout_section_col">
                <label for="cc_name">{{ getTranslation('CRCOWNER') }} <span class="error"></span></label>
                <input type="text" id="cc_name" name="cc_name" v-model="cc_name" />
            </div>
            <div class="checkout_section_col">
                <label for="cc_expire">{{ getTranslation('CRCSCA') }} <span class="error"></span></label>
                <div style="display: flex; align-items: center;">
                    <select id="cc_expire_month" name="cc_expire_month" v-model="cc_expire_month">
                        <option value="01">01</option>
                        <option value="02">02</option>
                        <option value="03">03</option>
                        <option value="04">04</option>
                        <option value="05">05</option>
                        <option value="06">06</option>
                        <option value="07">07</option>
                        <option value="08">08</option>
                        <option value="09">09</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                    </select>&nbsp; / &nbsp;
                    <select id="cc_expire_year" name="cc_expire_year" v-model="cc_expire_year">
                        <option v-for="expire_year in expire_years" v-bind:key="expire_year" v-bind:value="expire_year">{{ expire_year }}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="checkout_section_row">
            <div class="checkout_section_col checkboxes">
                <input id="privacy_policy" type="checkbox" value="1" v-model="privacy_policy">
                <label for="privacy_policy"><a href="#" @click.prevent="privacyPopup()">{{ getTranslation('PRIVACY') }}</a> &nbsp; <span class="error"></span></label>
            </div>
        </div>
        <div class="checkout_section_row">
            <div class="checkout_section_col checkboxes">
                <input id="terms" type="checkbox" value="1" v-model="terms">
                <label for="terms"><a href="#" @click.prevent="termsPopup()">{{ getTranslation('POLICYTEXT') }}</a> &nbsp; <span class="error"></span></label>
            </div>
        </div>
        <div class="quota_load_button_container">
            <div class="quota_load_button"  @click="acceptQuotaCard()">{{ getTranslation('QUOTA_ACCEPT') }}</div>
        </div>
        <!-- POP-UP -->
        <Modal :isVisible="show_modal" @close="closeModal">
            <div v-html="modal_message"></div>
        </Modal>
    </div>
</template>

<script>

import jQuery from 'jquery'; 
let $ = jQuery;
import Modal from './Modal.vue';

    export default {
        name: 'QuotaLoadPayment',

        components: {
            Modal
        },

        props: ['validation_token'],

        data: function() {
            return {
                payment_methods: '',
                cc_type: '',
                cc_number: '',
                cc_name: '',
                cc_expire_month: '',
                cc_expire_year: '',
                privacy_policy: false,
                terms: false,
                show_modal: false,
                modal_message: '',
            }
        },

        computed: {
            setCodeLang: function() {
                return String(this.$store.getters.get_lang_code);
            },
            expire_years: function() {
                let year = new Date().getFullYear();
                let years = [];
                for(let i = 0; i <= 10; i++) {
                    years.push(year);
                    year++;
                }
                
                return years;
            },    
            cc_type_nickname: function() {
                var self = this
                for (const [key, value] of Object.entries(this.payment_methods)) { // eslint-disable-line no-unused-vars
                    if(value.ID == self.cc_type) {
                        return value.NICKNAME;
                    }
                }
                return '';
            },
            quota_id: function() {
                return  this.$store.state.quota.quota_id;
            },
            nrchoice: function() {
                return this.$store.state.quota.nrchoice;
            },
            quotas: function() {
                var nrchoice = this.nrchoice;
                return this.$store.state.quotas[nrchoice];
            },
            checkin: function() {
                var nrchoice = this.nrchoice;
                return this.$store.state.quotas_checkin[nrchoice];
            },
            checkout: function() {
                var nrchoice = this.nrchoice;
                return this.$store.state.quotas_checkout[nrchoice];
            },
        },

        methods: {
            getTranslation: function(key) {
                return this.$store.getters.get_translation(key);
            },
            getPrivacyText: function() {
				let privacy_text = this.getTranslation('PRIVACYLAW');
				privacy_text = privacy_text.replace(/#COMPANY#/g, this.$store.state.company_info.name + ' - Partita IVA: ' + this.$store.state.company_info.vat);
				privacy_text = privacy_text.replace(/#EMAIL#/g, this.$store.state.company_info.email);
				return privacy_text;
			},
			privacyPopup: function() {
                window.$(`
                    <div class="privacy_popup" id="privacy_popup">
                        `+this.getPrivacyText()+`
                    </div>
                `).appendTo('body').modal();
            },
            termsPopup: function() {
                window.$(`
                    <div class="privacy_popup" id="privacy_popup">
                        `+this.quotas[0]['ROOM_POLICY_' + this.setCodeLang]+`
                    </div>
                `).appendTo('body').modal();
            },
            openModal() {
                this.show_modal = true; 
            },
            closeModal() {
                this.show_modal = false; 
            },
            //pagamento con carta di credito
            acceptQuotaCard: function() {
                const payload = {
                    quotaResult: this.quotas,
                    quotaCheckin: this.checkin,
                    quotaCheckout: this.checkout
                };
                this.$store.dispatch('getQuotaResult', payload)
                .then(responseData => {
                    if(responseData){
                        this.acceptQuotaCardExe();
                    }else{
                        this.statusUpdate();
                    }
                });
            },

            acceptQuotaCardExe: function () {
                let all_ok = true;

                if(this.privacy_policy == '') {
                    $('#privacy_policy').addClass('invalid_input');
                    $('#privacy_policy').siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i> ' + this.getTranslation('REQUIRED_FIELD'));
                    all_ok = false;
                }

                if(this.terms == '') {
                    $('#terms').addClass('invalid_input');
                    $('#terms').siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i> ' + this.getTranslation('REQUIRED_FIELD'));
                    all_ok = false;
                }

                if(this.cc_type == '') {
                    $('#cc_type').addClass('invalid_input');
                    $('#cc_type').siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i> ' + this.getTranslation('REQUIRED_FIELD'));
                    all_ok = false;
                }

                if(this.cc_number == '') {
                    $('#cc_number').addClass('invalid_input');
                    $('#cc_number').siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i> ' + this.getTranslation('REQUIRED_FIELD'));
                    all_ok = false;
                } else if(this.cc_number.length < 13 || this.cc_number.length > 16) {
                    $('#cc_number').addClass('invalid_input');
                    $('#cc_number').siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i> ' + this.getTranslation('VALUE_NOT_VALID'));
                    all_ok = false;
                }

                if(this.cc_name == '') {
                    $('#cc_name').addClass('invalid_input');
                    $('#cc_name').siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i> ' + this.getTranslation('REQUIRED_FIELD'));
                    all_ok = false;
                }

                if(this.cc_expire_month == '' && this.cc_expire_year == '') {
                    $('#cc_expire_month').addClass('invalid_input');
                    $('#cc_expire_year').addClass('invalid_input');
                    $('label[for=cc_expire]').find('.error').html('<i class="fas fa-exclamation-triangle"></i> ' + this.getTranslation('REQUIRED_FIELD'));
                    all_ok = false;
                } else if(this.cc_expire_month == '') {
                    $('#cc_expire_month').addClass('invalid_input');
                    $('label[for=cc_expire]').find('.error').html('<i class="fas fa-exclamation-triangle"></i> ' + this.getTranslation('REQUIRED_FIELD'));
                    all_ok = false;
                } else if(this.cc_expire_year == '') {
                    $('#cc_expire_year').addClass('invalid_input');
                    $('label[for=cc_expire]').find('.error').html('<i class="fas fa-exclamation-triangle"></i> ' + this.getTranslation('REQUIRED_FIELD'));
                    all_ok = false;
                } else {
                    if(this.cc_expire_year == new Date().getFullYear() && this.cc_expire_month < new Date().getMonth()) {
                        $('#cc_expire_month').addClass('invalid_input');
                        $('label[for=cc_expire]').find('.error').html('<i class="fas fa-exclamation-triangle"></i> ' + this.getTranslation('EXPIRATION_EXPIRED'));
                        all_ok = false;
                    }
                }
                if(all_ok) {
                    var self = this;

                    var bodyFormData = new FormData();
                    bodyFormData.append('quota_id', self.quota_id);
                    bodyFormData.append('nrchoice', self.nrchoice);
                    bodyFormData.append('validation_token', self.validation_token);
                    bodyFormData.append('cc_type', self.cc_type);
                    bodyFormData.append('cc_number', self.cc_number);
                    bodyFormData.append('cc_name', self.cc_name);
                    bodyFormData.append('cc_expire', self.cc_expire_month + '/' + self.cc_expire_year);
                    this.$axios({
                        method: 'post',
                        url: '/booking/'+self.$company+'/acceptQuotaCC',
                        data: bodyFormData
                    }).then((response) => {
                        if(response.data == 1){
                            self.$store.dispatch('quotaLoadsendConfirmationEmail');
                            self.modal_message = `<i class="far fa-check-circle" style="color: green; font-size: 40px;"></i><br><br><strong>${this.getTranslation('QUOTA_MODAL_CONF')}</strong>`
                        }else{
                            self.modal_message = `<i class="far fa-times-circle" style="color: red; font-size: 40px;"></i><br><br><strong>${this.getTranslation('QUOTA_TRY_AGAIN')}</strong>`
                        }
                        self.openModal();
                        
                    });
                }
            },
            //aggiorno stato su QUOTASRC se non c'è disponibilità
            statusUpdate: function() {
                var self = this;

                var bodyFormData = new FormData();
                bodyFormData.append('quota_id', self.quota_id);
                bodyFormData.append('nrchoice', self.nrchoice);
                bodyFormData.append('validation_token', self.validation_token);
                this.$axios({
                    method: 'post',
                    url: '/booking/'+self.$company+'/statusUpdate',
                    data: bodyFormData
                }).then((response) => {
                    if(response.data == 1){
                        self.$store.dispatch('quotaLoadSendNoDisponibilityMail');
                        self.modal_message = `<i class="far fa-times-circle" style="color: red; font-size: 40px;"></i><br><br><strong>${this.getTranslation('QUOTA_NOT_AVAILABLE')}</strong>`
                    }else{
                        self.modal_message = `<i class="far fa-times-circle" style="color: red; font-size: 40px;"></i><br><br><strong>${this.getTranslation('QUOTA_TRY_AGAIN')}</strong>`
                    }
                    self.openModal();
                });
            }, 
        },

        created:function() {

            var self = this;
            this.$axios({
                method: 'get',
                url: '/booking/'+this.$company+'/getPaymentMethods/'+self.$store.state.company_id,
            }).then(function (response) {
                self.payment_methods = response.data;
            });
        },

        //funzione per eliminare gli error nei form
        mounted: function() {
            jQuery('input').focus(function() {
                $(this).removeClass('invalid_input');
                $(this).siblings('label').find('.error').html('');
            });
            jQuery('select').focus(function() {
                $(this).removeClass('invalid_input');
                $(this).siblings('label').find('.error').html('');
                if($(this).attr('id') == 'cc_expire_month' || $(this).attr('id') == 'cc_expire_year') {
                    $('label[for=cc_expire]').find('.error').html('');
                }
            });
        },
    }
</script>