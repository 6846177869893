<template>
    <div class="quota_load_section">
    <h2>{{ getTranslation('QUOTA_ ADDITIONAL_SERVICES') }}</h2>
        <div class="quota_load_section_extra_charges_row" v-for="(additional_services, key) in additional_services" :key="key">
            <div class="quota_load_section_extra_charges_row_top" v-if="additional_services['spazio'] != ''">
                <h4>{{ additional_services['spazio'] }}</h4>
                <span>{{ additional_services['descrizione_spazio_' + setCodeLang] }} - {{ additional_services['QTY'] }} pax</span><br>
                <span v-html="formatDateRange(key)"></span>
            </div>
            <div class="quota_load_section_extra_charges_row_top" v-if="additional_services['prodotto_' + setCodeLang] != ''" style="display: flex; gap: 5px;">
                <span v-if="additional_services['QTY'] > 1">{{ additional_services['QTY'] }}x</span>
                <h4>{{ additional_services['prodotto_' + setCodeLang] }}</h4>
            </div>
            <div class="quota_load_section_extra_charges_row_top" v-if="additional_services['trattamento_' + setCodeLang] != ''">
                <div style="display: flex; gap: 5px;">
                    <span v-if="additional_services['QTY'] > 1">{{ additional_services['QTY'] }}x</span>
                    <h4>{{ additional_services['trattamento_' + setCodeLang] }}</h4>
                </div>
                <span v-html="formatDateRange(key)"></span>
            </div>
            <div class="quota_load_section_extra_charges_row_bottom">
                <div class="secondario" v-if="additional_services['CORRECTIONRATE'] != 0.00 && totalNoDiscount(additional_services['TOTALPRICE'], additional_services['CORRECTIONRATE'] * additional_services['QTY']) != null">
                    <del>
                        {{ totalNoDiscount(additional_services['TOTALPRICE'], additional_services['CORRECTIONRATE'] * additional_services['QTY']) }} €
                    </del>
                </div>
                <div style="color: var(--mainColor);"><b>{{ formatPrice(additional_services['TOTALPRICE']) }} €</b></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'QuotaLoadServices',

        computed: {
            setCodeLang: function() {
                return String(this.$store.getters.get_lang_code);
            },
            additional_services: function() {
                var nrchoice = this.$store.state.quota.nrchoice;
                return this.$store.state.quotas_services[nrchoice];
            }
        },

        methods: {
            getTranslation: function(key) {
                return this.$store.getters.get_translation(key);
            },
            formatPrice(price) {
                let number = parseFloat(price);
                return number.toFixed(2).replace('.', ',');
            },
            formatDateRange(key) {
                let start = new Date(this.additional_services[key]['START']);
                let stop = new Date(this.additional_services[key]['STOP']);

                let day = start.getDate().toString().padStart(2, '0');
                let month = (start.getMonth() + 1).toString().padStart(2, '0');
                let year = start.getFullYear();

                if(year == 1800 ){
                    return ''
                }
                
                let startTime = start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                let stopTime = stop.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                
                return `${day}/${month}/${year} - ${startTime}-${stopTime}`;
            },
            totalNoDiscount(price, correction) {

                if(correction >= 0){
                    return 
                }
                let totale = Number(price) + (-correction)

                if(totale == 0){
                    return 
                }

                let formatTotale = parseFloat(totale);
                return formatTotale.toFixed(2).replace('.', ',');
            }
        }
    }
</script>