<template>
	<div id="search-result">
		<div class="search_result_row_1">
			<a class="search_result_image" v-bind:style="{ backgroundImage: 'url(' + image1 + ')' }" :href="image1" :data-lightbox="'lightbox_'+this.result_data.ID">
				<span>{{ title }}</span>	
			</a>
			<a class="image_gallery" v-if="image2" :href="image2" :data-lightbox="'lightbox_'+this.result_data.ID">#</a>
			<a class="image_gallery" v-if="image3" :href="image3" :data-lightbox="'lightbox_'+this.result_data.ID">#</a>
			<div style="padding: 20px;">
				<div class="search_result_description">
					<h3 v-html="title"></h3>
					<span v-html="description"></span>
				</div>
				<br>
				<div class="search_result_policy_policy" v-html="policyName"></div>
				<a class="secondario" style="cursor: pointer;" @click.prevent="termsPopup(policyDescription)" href="">
					{{ getTranslation('QUOTA_POLICY') }}
				</a>
				<div class="general_price">
					<div class="search_result_policy_price">{{ this.result_data.TOTAL_PRICE_FORMATTED }} €</div>
					<div class="search_result_policy_price_tip">{{ getTranslation('PRICEFOR') }} {{ nights }} {{ nights_text }}</div>
					<div class="search_result_button" @click="selectPackageRoom()" style="width: 40%; text-align: center;">{{ getTranslation('PROCEED') }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import lightbox from 'lightbox2'

export default {
	name: 'PackagesSearchRoom',
	props: ['result_data'],
	computed: {
		image1: function() {
			var image = encodeURI(this.$baseURL + '/structures/'+this.$company+'/'+this.$store.state.company_id+'/rooms/' + this.result_data.ID + '/' + this.result_data.IMAGE1);
			image = image.replace("(", "%28");
			image = image.replace(")", "%29");
			return image;
		},
		image2: function() {
			if(this.result_data.IMAGE2 != '') {
				var image = encodeURI(this.$baseURL + '/structures/'+this.$company+'/'+this.$store.state.company_id+'/rooms/' + this.result_data.ID + '/' + this.result_data.IMAGE2);
				image = image.replace("(", "%28");
				image = image.replace(")", "%29");
				return image;
			} else {
				return false;
			}
		},
		image3: function() {
			if(this.result_data.IMAGE3 != '') {
				var image = encodeURI(this.$baseURL + '/structures/'+this.$company+'/'+this.$store.state.company_id+'/rooms/' + this.result_data.ID + '/' + this.result_data.IMAGE3);
				image = image.replace("(", "%28");
				image = image.replace(")", "%29");
				return image;
			} else {
				return false;
			}
		},
		title: function() {
			return this.result_data['NAME_'+this.$store.getters.get_lang_code];
		},
		description: function() {
			return this.result_data['DESCRIPTION_'+this.$store.getters.get_lang_code];
		},
		policyName: function() {
			return this.result_data['POLICY']['POLICY_NAME_'+this.$store.getters.get_lang_code];
		},
		policyDescription: function() {
			return this.result_data['POLICY']['POLICY_DESCRIPTION_'+this.$store.getters.get_lang_code];
		},
		nights: function() {
			return this.$calculateNights(new Date(this.$store.state.check_in), new Date(this.$store.state.check_out));
		},
		nights_text: function() {
			if(this.nights == 1) {
				return this.getTranslation('NIGHT');
			} else {
				return this.getTranslation('NIGHTS');
			}
		}
	},
	methods: {
		termsPopup: function(policy) {
			window.$(`
				<div class="privacy_popup" id="policy_popup">
					`+policy+`
				</div>
			`).appendTo('body').modal();
		},
		getTranslation: function(key) {
			return this.$store.getters.get_translation(key);
		},
		selectPackageRoom: function() {
			this.$store.commit('setPackageRoomSelected', this.result_data);

			if(this.$store.state.tracking_codes['TRACKING_SCRIPT_ROOM_SELECTED'] != undefined) {
				let tracking_code = this.$store.state.tracking_codes['TRACKING_SCRIPT_ROOM_SELECTED'];

				let piwik_items = `
					{
						sku: '`+this.result_data['ID']+`',
						name: '`+this.result_data['NAME_IT']+`',
						price: `+this.result_data['TOTAL_PRICE'].toFixed(2)+`,
						quantity: 1,
					},
				`;

				tracking_code = tracking_code.replace(/%piwikitems%/g, piwik_items);

				tracking_code = tracking_code.replace(/%total%/g, this.result_data['TOTAL_PRICE'].toFixed(2));

				tracking_code = tracking_code.replace(/%rid/g, this.result_data['ID']);
				tracking_code = tracking_code.replace(/%rn/g, this.result_data['NAME_IT']);
				tracking_code = tracking_code.replace(/%p/g, this.result_data['TOTAL_PRICE'].toFixed(2));
				eval(tracking_code);
			}

			this.$store.dispatch('getPackageExtraCharges');
		}
	},
	mounted: function() {
		lightbox.option({
			albumLabel: '',
			wrapAround: true,
			fadeDuration: 300
		})
	}
}
</script>

<style>
.general_price{
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-content: flex-end;
	align-items: center;
}
</style>